@charset "utf-8";

/* AMQ */
@font-face {
  font-family: 'bemboregular';
  src: url('assets/fonts/amq/20740564562.ttf') format('truetype'),
  url('assets/fonts/amq/20740564562-webfont.woff') format('woff'),
  url('assets/fonts/amq/20740564562-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* BAL */
@font-face {
  font-family: 'universltcyr';
  src: url('assets/fonts/balanciaga/UniversLTCYR-55Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'universltcyr';
  src: url('assets/fonts/balanciaga/UniversLTCYR-67BoldCond.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* BRIONI */
@font-face {
  font-family: 'labgrotesque';
  src: url('assets/fonts/brioni/labGrotesque/LabGrotesque-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'labgrotesque';
  src: url('assets/fonts/brioni/labGrotesque/LabGrotesque-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* BV */
@font-face {
  font-family: 'bv';
  src: url('assets/fonts/bv/BottegaVeneta-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bv';
  src: url('assets/fonts/bv/BottegaVeneta-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* GUCCI */
@font-face {
  font-family: 'guccisans';
  src: url('assets/fonts/gucci/TTF/GucciSansPro-Medium.ttf') format('truetype'),
  url('assets/fonts/gucci/OTF/GucciSansPro-Medium.otf') format('truetype'),
  url('assets/fonts/gucci/WOFF/GucciSansPro-Medium.woff') format('woff'),
  url('assets/fonts/gucci/WOFF2/GucciSansPro-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'guccisans';
  src: url('assets/fonts/gucci/TTF/GucciSansPro-Bold.ttf') format('truetype'),
  url('assets/fonts/gucci/OTF/GucciSansPro-Bold.otf') format('truetype'),
  url('assets/fonts/gucci/WOFF/GucciSansPro-Bold.woff') format('woff'),
  url('assets/fonts/gucci/WOFF2/GucciSansPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/* YSL */
@font-face {
  font-family: "HelveNeu";
  font-weight: normal;
  src: url('assets/fonts/ysl/HelveNeuMed') format('truetype');
}
@font-face {
  font-family: "HelveNeu";
  font-weight: bold;
  src: url('assets/fonts/ysl/HelveNeuBol') format('truetype');
}

/* GUCCI */
@font-face {
  font-family: 'futuralight';
  src:  local('Futura Light'),
  url('assets/fonts/Futura_Light.ttf') format('truetype'),
  url('assets/fonts/futura_light-webfont.woff2') format('woff2'),
  url('assets/fonts/futura_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

a:link {
  color: black
}

a:visited {
  color:black
}

body {
  font-size: 11pt;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  touch-action: pan-y;
  -ms-overflow-style: none;  /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input[type="text" i] {
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.grecaptcha-badge {
  bottom: 0 !important;
}

div::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome, Safari and Opera */
}

input:disabled {
  background-color: transparent
}

